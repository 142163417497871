import fetch from "cross-fetch";
import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";

const url = `${process.env.GATSBY_WORDPRESS_URL}/graphql`;

const httpLink = new HttpLink({
  uri: url,
  fetch,
  credentials: "include",
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([httpLink]),
});

export default client;
